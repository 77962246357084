export const Cta = (props) => {
  return (
    <div id='cta' className='text-center'>
      <div className='container'>
        <div className='row'>
          <div className="col-lg-6 col-sm-6 col-xs-12">
        <div className='section-title-white'>
          <h2>Nie masz pomysłu na prezent?</h2>
          <p className={"text-black"}>
            Kup bliskiej osobie voucher na strzelnie, który jest ważny pół roku!
          </p>
        </div>
          </div>
          <div className="col-lg-6 col-sm-6 col-xs-12 btn-cta-container">
            <button
                data-id="1ec74d51-29d4-4fd1-a91f-0b284e74b5ff"
                data-color="f9b100"
                className='btn btn-cta btn-lg page-scroll bookitone-booking-init'
            >
              Kup voucher
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
