import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

export const Gallery = (props) => {
    const images = [...Array(13).keys()].map((number) => ({
        src: `img/portfolio/${number}.jpg`
    }));

  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Galeria</h2>
        </div>
          <Carousel images={images} style={{ maxHeight: "75%", width: "100%", minWidth: "300px", minHeight: "400px"}} objectFit={'contain'} isAutoPlaying={true} />
          <div className='row'>
        </div>
      </div>
    </div>
  )
}
