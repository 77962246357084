export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                </h1>
                <button
                  data-id="1ec74d51-29d4-4fd1-a91f-0b284e74b5ff"
                  data-color="f9b100"
                  className='btn btn-custom btn-lg page-scroll bookitone-booking-init mt-2'
                >
                  Zarezerwuj swoją przygodę
                </button>{' '}
                <h3 style={{marginTop: "100px", color: "#fff", marginBottom: '24px'}}>SPRAWDŹ NASZE SZKOLENIA</h3>
                <div className='row'>
                  <div className='col-md-5'>
                    <div className={'img-cover'}/>
                    <div className='course-description'>
                      <h3>Szkolenie do egzaminu na pozwolenie na broń</h3>
                      <div className={'price'}>1000 zł</div>
                      <a
                          href='/?product=egz&club=true'
                          data-color="f9b100"
                          className='btn btn-custom btn-lg page-scroll  check-training'
                      >
                        Sprawdź szkolenie
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
