export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'/>{' '}
            <span className='icon-bar'/>{' '}
            <span className='icon-bar'/>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
            Strzelnica <br/>Historyczna
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            {/*<li>*/}
            {/*  <a href='#features' className='page-scroll'>*/}
            {/*    Features*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li>
              <a href='#about' className='page-scroll'>
                O nas
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Oferta
              </a>
            </li>
            <li>
              <a href='#portfolio' className='page-scroll'>
                Galeria
              </a>
            </li>
            {/*<li>*/}
            {/*  <a href='#testimonials' className='page-scroll'>*/}
            {/*    Testimonials*/}
            {/*  </a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <a href='#team' className='page-scroll'>*/}
            {/*    Team*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li>
              <a href='#contact' className='page-scroll'>
                Kontakt
              </a>
            </li>
            <li>
              <a href={"/"} className={"bookitone-booking-init"} data-id="1ec74d51-29d4-4fd1-a91f-0b284e74b5ff" data-color="f9b100" style={{color: "#f6b200"}}>Sklep</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
